import { Fragment, useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
import { createContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const UserContext = createContext();

const parseUser = () => {
  try {
    return JSON.parse(window.localStorage.getItem("user"));
  } catch {
    return null;
  }
};

const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState(parseUser());

  const setUserWrapper = (user) => {
    setUser(user);
    window.localStorage.setItem("user", JSON.stringify(user));
  };

  return (
    <UserContext.Provider
      value={{
        user: user,
        setUser: setUserWrapper,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
export default UserContextProvider;

export const RequireLoggedIn = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useContext(UserContext);

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [location]);

  return <Fragment>{children}</Fragment>;
};
