import { useParams } from "react-router-dom";
import EmailConfirmation from "../../components/email-confirmation/EmailConfirmation";
import AppLayout from "../../components/layouts/AppLayout";
import { Box } from "@mui/material";

const EmailConfirmationPage = () => {
  const { token } = useParams();

  return (
    <AppLayout>
      <Box
        sx={{
          display: "flex",
          minHeight: "60vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <EmailConfirmation token={token} />
      </Box>
    </AppLayout>
  );
};
export default EmailConfirmationPage;
