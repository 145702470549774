import AppLayout from "../layouts/AppLayout";

const Cart = () => {
  return (
    <AppLayout>
      <p>cart</p>
    </AppLayout>
  );
};

export default Cart;
