import AppLayout from "../components/layouts/AppLayout";
import Login from "../components/sessions/Login";
import { Box } from "@mui/material";

const LoginPage = () => {
  return (
    <AppLayout>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "70vh",
        }}
      >
        <Login />
      </Box>
    </AppLayout>
  );
};
export default LoginPage;
