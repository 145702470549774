import { useEffect } from "react";
import { useState } from "react";
import { createContext } from "react";

const languages = {
  pl: {
    home: "Home",
    products: "Produkty",
    cart: "Koszyk",
    account: "Konto",
    contact: "Kontakt",
    language: "Język",

    login: "Zaloguj się",
    email: "Email",
    password: "Hasło",
    login_action: "Zaloguj się",
    reset_password: "Resetuj hasło",
    signup: "Zarejestruj się",
    first_name: "Imię",
    last_name: "Nazwisko",
    signup_action: "Zarejestruj się",
    agreement_gdpr:
      "Zgoda na przetwarzanie danych osobowych na podstawie ust... RODO",
    agreement_marketing:
      "Zgoda na otrzymywanie informacji marketingowych przez ...",
    email_welcome: "Dziekujemy za rejestreację w naszym serwisie",
    signup_welcome_title: "Dzięujemy za rejestrację",
    signup_welcome_content:
      "Prosimy teraz sprawdzić skrzynkę email w celu aktywacji konta",
    email_welcome_title: "Adres email został zweryfikowany",
    email_welcome_content:
      "Dziękujemy za rejestrację. Teraz możesz zalogować się na swoje konto",

    error_occured: "Wysąpił Błąd",
    error_field_required: "Pole wymagane",
    error_email: "Niepoprawny adres email",
    error_password_min8: "Hasło nie może mieć mniej niż 8 znaków",
    error_400: "Niepoprawne żądanie",
    error_401: "Nieautoryzowany dostęp",
    error_404: "Nie znaleziono elementu",
    error_601: "Niepoprawne dane logowania",
    error_602:
      "Email nie został zweryfikowany, prosimy sprawdzić skrzynkę pocztową",
    error_603: "Email został już zweryfikowany",
    error_606: "Email został już wykożystany do utworzenia konta",
    error_unknown: "Wystąpił niespodziewany błąd",
  },
  en: {
    home: "Home",
    products: "Products",
    cart: "Cart",
    account: "Account",
    contact: "Contact",
    language: "Lang",

    login: "Login",
    email: "Email",
    password: "Password",
    login_action: "Login",
    reset_password: "Reset password",
    signup: "Sign up",
    first_name: "First name",
    last_name: "Last name",
    signup_action: "Sign up",
    agreement_gdpr: "I agree to data handling policy according to ... RODO",
    agreement_marketing: "I agree to recieve marketing emails from ...",
    email_welcome: "Thank you for signing up to our service",
    signup_welcome_title: "Thank you for signing up",
    signup_welcome_content: "Please check your email to activate your account",
    email_welcome_title: "Email successfully verified",
    email_welcome_content: "Thank you for signing up. Now you can log in",

    error_occured: "An error occured",
    error_field_required: "This field is required",
    error_email: "Incorrect email address",
    error_password_min8: "Password minimal length is 8 characters",
    error_400: "Bad request",
    error_401: "Unauthorized",
    error_404: "Element not found",
    error_601: "Bad credentials",
    error_602: "Email not yet verified, please check your inbox",
    error_603: "Email has already been verified",
    error_606: "This email is already in use",
    error_unknown: "An unknown error has occured",
  },
};

export const flags = {
  pl: "🇵🇱",
  en: "🇬🇧",
};

export const Languagepack = createContext(languages.pl);
export const SelectedLanguage = createContext("pl");

const LanguagepackProvider = ({ children }) => {
  const lang = window.localStorage.getItem("lang");
  const [languagepack, setLanguagepack] = useState(languages[lang || "pl"]);
  const [selectedLanguage, setSelectedLanguage] = useState(lang || "pl");

  useEffect(() => {
    setLanguagepack(languages[selectedLanguage]);
    window.localStorage.setItem("lang", selectedLanguage);
  }, [selectedLanguage]);

  return (
    <SelectedLanguage.Provider
      value={{
        selectedLanguage: selectedLanguage,
        setSelectedLanguage: setSelectedLanguage,
      }}
    >
      <Languagepack.Provider value={languagepack}>
        {children}
      </Languagepack.Provider>
    </SelectedLanguage.Provider>
  );
};
export default LanguagepackProvider;
