import { Box, Button, Card, Typography } from "@mui/material";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Languagepack } from "../../languagepack/LanguagepackProvider";

const EmailConfirmationWelcome = () => {
  const languagepack = useContext(Languagepack);
  const naviagte = useNavigate();

  return (
    <Card
      sx={{
        padding: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography variant="h6">{languagepack.email_welcome_title}</Typography>
      <Typography>{languagepack.email_welcome_content}</Typography>
      <Box
        sx={{
          display: "flex",
          padding: 1,
          gap: 1,
        }}
      >
        <Button
          variant="outlined"
          onClick={() => {
            naviagte("/");
          }}
        >
          {languagepack.home}
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            naviagte("/login");
          }}
        >
          {languagepack.login}
        </Button>
      </Box>
    </Card>
  );
};
export default EmailConfirmationWelcome;
