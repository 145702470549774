import { Box, Button, Card, CircularProgress, Typography } from "@mui/material";
import { Fragment, useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import apiClient from "../../api/client";
import { Languagepack } from "../../languagepack/LanguagepackProvider";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const EmailConfirmation = ({ token }) => {
  const languagepack = useContext(Languagepack);
  const [isFetching, setIsFetching] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    apiClient
      .confirmEmail(token)
      .then((success) => {
        if (success) {
          setIsFetching(false);
        }
      })
      .catch((err) => {
        apiClient.handleErrors(err);
      });
  }, [token]);

  return (
    <Card
      sx={{
        padding: 2,
        minWidth: 250,
        minHeight: 200,
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      {!!isFetching && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {!isFetching && (
        <Fragment>
          <Typography>{languagepack.email_welcome}</Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CheckCircleOutlineIcon
              fontSize="small"
              sx={{
                width: "30%",
                height: "100%",
              }}
              color="success"
            />
          </Box>

          <Button
            variant="contained"
            onClick={() => {
              navigate("/login");
            }}
          >
            {languagepack.login}
          </Button>
        </Fragment>
      )}
    </Card>
  );
};
export default EmailConfirmation;
