import theme from "./theme/theme";
import { ThemeProvider } from "@mui/material";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import IndexPage from "./pages";
import ServicesProvider from "./services/ServicesProvider";
import AdminIndexPage from "./pages/admin";
import LanguagepackProvider from "./languagepack/LanguagepackProvider";
import UserContextProvider from "./userContext/UserContext";
import AccountPage from "./pages/account";
import LoginPage from "./pages/login";
import SignupPage from "./pages/signup";
import EmailConfirmationPage from "./pages/email-confirmation/[token]";
import SignupWelcomePage from "./pages/signup/welcome";
import EmailWelcomePage from "./pages/email-confirmation/welcome";
import Products from "./components/products/Products";
import Cart from "./components/cart/Cart";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <UserContextProvider>
          <LanguagepackProvider>
            <ServicesProvider>
              <Routes>
                <Route path="/" element={<IndexPage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/signup">
                  <Route path="" element={<SignupPage />} />
                  <Route path="welcome" element={<SignupWelcomePage />} />
                </Route>
                <Route path="/email-confirmation">
                  <Route path=":token" element={<EmailConfirmationPage />} />
                  <Route path="welcome" element={<EmailWelcomePage />} />
                </Route>
                <Route path="/account">
                  <Route path="" element={<AccountPage />} />
                </Route>
                <Route path="/admin">
                  <Route path="" element={<AdminIndexPage />} />
                </Route>
                <Route path="/products">
                  <Route path="" element={<Products />} />
                </Route>
                <Route path="/cart">
                  <Route path="" element={<Cart />} />
                </Route>
              </Routes>
            </ServicesProvider>
          </LanguagepackProvider>
        </UserContextProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
