import AppLayout from "../components/layouts/AppLayout";
import Home from "../components/home/Home";

const IndexPage = () => {
  return (
    <AppLayout>
      <Home />
    </AppLayout>
  );
};
export default IndexPage;
