import { Languagepack } from "../../languagepack/LanguagepackProvider";
import * as yup from "yup";
import { UserContext } from "../../userContext/UserContext";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { LoadingButton } from "@mui/lab";
import { Card, Divider, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useContext } from "react";
import apiClient from "../../api/client";

const Login = () => {
  const languagepack = useContext(Languagepack);
  const { setUser } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    apiClient.isLoggedIn().then((isLoggedIn) => {
      if (isLoggedIn) {
        navigate("/account");
      }
    });
  }, []);

  const validationSchema = yup.object({
    email: yup
      .string()
      .email(languagepack.error_email)
      .required(languagepack.error_field_required),
    password: yup.string().required(languagepack.error_field_required),
  });
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const response = await apiClient.login({
          email: values.email,
          password: values.password,
        });

        setUser(response.data);

        formik.setSubmitting(false);

        navigate("/account");
      } catch (e) {
        apiClient.handleErrors(e);
      }
    },
  });

  return (
    <Card
      sx={{
        padding: 2,
        display: "flex",
        flexDirection: "column",
        gap: 1,
        minWidth: "25vw",
      }}
    >
      <Typography variant="h6" color="primary">
        {languagepack.login}
      </Typography>
      <TextField
        name="email"
        placeholder={languagepack.email}
        value={formik.values.email}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={!!formik.touched.email && !!formik.errors.email}
        helperText={!!formik.touched.email && formik.errors.email}
        variant="standard"
      />
      <TextField
        name="password"
        type="password"
        placeholder={languagepack.password}
        value={formik.values.password}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={!!formik.touched.password && !!formik.errors.password}
        helperText={!!formik.touched.password && formik.errors.password}
        variant="standard"
      />
      <LoadingButton
        loading={formik.isSubmitting}
        variant="contained"
        onClick={formik.handleSubmit}
      >
        {languagepack.login_action}
      </LoadingButton>

      <Divider />

      <Typography
        to="/reset-password"
        component={Link}
        sx={{
          color: "gray",
          textAlign: "center",
        }}
      >
        {languagepack.reset_password}
      </Typography>

      <Divider />

      <Typography
        to="/signup"
        component={Link}
        sx={{
          color: "gray",
          textAlign: "center",
        }}
      >
        {languagepack.signup}
      </Typography>
    </Card>
  );
};
export default Login;
