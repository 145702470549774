import { Snackbar, Alert } from "@mui/material";
import { Fragment, useEffect, useState } from "react";

const SnackbarServiceHandler = ({ children }) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  useEffect(() => {
    snackbarService.showSnackbar = showSnackbar;
  }, []);

  return (
    <Fragment>
      <Snackbar
        open={snackbarOpen}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>

      {children}
    </Fragment>
  );
};
class SnackbarService {
  constructor() {
    this._showSnackbar = null;
  }
  set showSnackbar(showSnackbar) {
    this._showSnackbar = showSnackbar;
  }
  get showSnackbar() {
    return this._showSnackbar;
  }
}
export const snackbarService = new SnackbarService();
export default SnackbarServiceHandler;
