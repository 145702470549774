import { useEffect, useState } from "react";
import apiClient from "../../api/client";
import { CircularProgress } from "@mui/material";

const Home = () => {
  const [featuredProducts, setFeaturedProducts] = useState();

  useEffect(() => {
    apiClient
      .getFeaturedProducts()
      .then((res) => {
        setFeaturedProducts(res.data);
      })
      .catch((e) => {
        console.warn(e);
      });
  }, []);

  return (
    <>
      {!featuredProducts && <CircularProgress />}
      {!!featuredProducts &&
        featuredProducts.map((product) => (
          <>
            <img
              key={product.uuid}
              src={product.product_images[0].path}
              alt={""}
            />
            <p>{product.system_name}</p>
          </>
        ))}
    </>
  );
};

export default Home;
