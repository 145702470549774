import { Box } from "@mui/material";
import AppLayout from "../../components/layouts/AppLayout";
import Signup from "../../components/sessions/Signup";

const SignupPage = () => {
  return (
    <AppLayout>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "70vh",
        }}
      >
        <Signup />
      </Box>
    </AppLayout>
  );
};
export default SignupPage;
