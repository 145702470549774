import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#aa3bbb",
      900: "#45008f",
      800: "#67049b",
      700: "#7907a3",
      600: "#8c0baa",
      500: "#9b0caf",
      400: "#aa3bbb",
      300: "#b960c7",
      200: "#cd8ed7",
      100: "#e1bbe7",
    },
    secondary: {
      main: "#60a30f",
    },
  },
});
export default theme;
