import {
  Card,
  FormControlLabel,
  TextField,
  Typography,
  Checkbox,
  FormHelperText,
} from "@mui/material";
import { useFormik } from "formik";
import { useContext } from "react";
import * as yup from "yup";
import { Languagepack } from "../../languagepack/LanguagepackProvider";
import { LoadingButton } from "@mui/lab";
import apiClient from "../../api/client";
import { useNavigate } from "react-router-dom";

const Signup = () => {
  const languagepack = useContext(Languagepack);
  const navigate = useNavigate();

  const validationSchema = yup.object({
    email: yup
      .string()
      .email(languagepack.error_email)
      .required(languagepack.error_field_required),
    first_name: yup.string().required(languagepack.error_field_required),
    last_name: yup.string().required(languagepack.error_field_required),
    password: yup
      .string()
      .min(8, languagepack.error_password_min8)
      .required(languagepack.error_field_required),
    gdpr_accepted: yup
      .bool()
      .required(languagepack.error_field_required)
      .oneOf([true], languagepack.error_field_required),
    marketing_accepted: yup.bool(),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      first_name: "",
      last_name: "",
      password: "",
      gdpr_accepted: false,
      marketing_accepted: false,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        await apiClient.signup(values);
        formik.setSubmitting(false);
        navigate("welcome");
      } catch (error) {
        apiClient.handleErrors(error);
      }
    },
  });

  return (
    <Card
      sx={{
        padding: 2,
        display: "flex",
        flexDirection: "column",
        gap: 1,
        minWidth: "25vw",
        maxWidth: 600,
      }}
    >
      <Typography variant="h6" color="primary">
        {languagepack.signup}
      </Typography>
      <TextField
        name="first_name"
        placeholder={languagepack.first_name}
        value={formik.values.first_name}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={!!formik.touched.first_name && !!formik.errors.first_name}
        helperText={!!formik.touched.first_name && formik.errors.first_name}
        variant="standard"
      />
      <TextField
        name="last_name"
        placeholder={languagepack.last_name}
        value={formik.values.last_name}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={!!formik.touched.last_name && !!formik.errors.last_name}
        helperText={!!formik.touched.last_name && formik.errors.last_name}
        variant="standard"
      />
      <TextField
        name="email"
        placeholder={languagepack.email}
        value={formik.values.email}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={!!formik.touched.email && !!formik.errors.email}
        helperText={!!formik.touched.email && formik.errors.email}
        variant="standard"
      />
      <TextField
        name="password"
        type="password"
        placeholder={languagepack.password}
        value={formik.values.password}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={!!formik.touched.password && !!formik.errors.password}
        helperText={!!formik.touched.password && formik.errors.password}
        variant="standard"
      />
      <FormControlLabel
        sx={{ margin: 0 }}
        label={
          <Typography
            sx={{
              color: "grey.600",
              fontSize: 12,
            }}
          >
            {languagepack.agreement_gdpr}
          </Typography>
        }
        control={
          <Checkbox
            checked={!!formik.values.gdpr_accepted}
            onChange={(_, checked) => {
              formik.setFieldValue("gdpr_accepted", checked);
            }}
          />
        }
      />
      {!!formik.touched.gdpr_accepted && !!formik.errors.gdpr_accepted && (
        <FormHelperText error>{formik.errors.gdpr_accepted}</FormHelperText>
      )}

      <FormControlLabel
        sx={{ margin: 0 }}
        label={
          <Typography
            sx={{
              color: "grey.600",
              fontSize: 12,
            }}
          >
            {languagepack.agreement_marketing}
          </Typography>
        }
        control={
          <Checkbox
            checked={!!formik.values.marketing_accepted}
            onChange={(_, checked) => {
              formik.setFieldValue("marketing_accepted", checked);
            }}
          />
        }
      />

      <LoadingButton
        loading={formik.isSubmitting}
        variant="contained"
        onClick={formik.handleSubmit}
      >
        {languagepack.signup_action}
      </LoadingButton>
    </Card>
  );
};
export default Signup;
