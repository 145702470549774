import AppLayout from "../layouts/AppLayout";
import { useEffect, useState } from "react";
import apiClient from "../../api/client";
import {
  Box,
  CircularProgress,
  ImageList,
  ImageListItem,
  ImageListItemBar,
} from "@mui/material";

const Products = () => {
  const [products, setProducts] = useState();

  useEffect(() => {
    apiClient
      .getProducts()
      .then((response) => {
        setProducts(response.data);
      })
      .catch((e) => {
        console.warn(e);
      });
  }, []);

  return (
    <AppLayout>
      {!products && <CircularProgress />}
      {!!products && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <ImageList sx={{ width: 500, height: 450 }}>
            {products.map((product) => (
              <ImageListItem key={product.img}>
                <img
                  key={product.uuid}
                  // src={product.product_images[0].path}
                  src={`${product.product_images[0].path}?w=164&h=164&fit=crop&auto=format`}
                  srcSet={`${product.product_images[0].path}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                  alt={product.name}
                  loading="lazy"
                />
                <ImageListItemBar
                  title={product.name}
                  subtitle={<span>uuid: {product.uuid}</span>}
                  position="below"
                />
              </ImageListItem>
            ))}
          </ImageList>
        </Box>
      )}
    </AppLayout>
  );
};
export default Products;
