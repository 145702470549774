import { Fragment } from "react";
import ErrorServiceHandler from "./errorService";
import SnackbarServiceHandler from "./snackbarService";
import UserServiceHandler from "./userService";

const ServicesProvider = ({ children }) => {
  return (
    <Fragment>
      <SnackbarServiceHandler />
      <UserServiceHandler />
      <ErrorServiceHandler />
      {children}
    </Fragment>
  );
};
export default ServicesProvider;
