import {
  List,
  ListItemButton,
  ListItemText,
  SwipeableDrawer,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const AdminMenu = ({ open, toggleMenu }) => {
  const navigate = useNavigate();

  const navigations = [
    {
      label: "Zmiana hasła",
      href: "/admin/password",
    },
    {
      label: "Wyloguj",
      href: "/admin/logout",
    },
  ];

  return (
    <SwipeableDrawer
      anchor="left"
      open={open}
      onClose={toggleMenu(false)}
      onOpen={toggleMenu(true)}
    >
      <List
        sx={{
          minWidth: "300px",
        }}
      >
        {navigations.map((elem) => (
          <ListItemButton
            key={elem.href}
            onClick={() => {
              navigate(elem.href);
            }}
          >
            <ListItemText>{elem.label}</ListItemText>
          </ListItemButton>
        ))}
      </List>
    </SwipeableDrawer>
  );
};

export default AdminMenu;
