import { useContext } from "react";
import { useEffect } from "react";
import { Fragment } from "react";
import { UserContext } from "../userContext/UserContext";

export const UserServiceHandler = ({ children }) => {
  const { user } = useContext(UserContext);
  useEffect(() => {
    userService.user = user;
  }, [user]);
  return <Fragment>{children}</Fragment>;
};
class UserService {
  constructor() {
    this._user = null;
  }
  set user(user) {
    this._user = user;
  }
  get user() {
    return this._user;
  }
}
export const userService = new UserService();
export default UserServiceHandler;
