import { Fragment } from "react";
import Header from "../header/Header";

const AppLayout = ({ title, children }) => {
  return (
    <Fragment>
      <Header />
      {children}
    </Fragment>
  );
};
export default AppLayout;
