import { Close } from "@mui/icons-material";
import { Box, Dialog, IconButton, Typography } from "@mui/material";
import { useContext } from "react";
import { Fragment, useState, useEffect } from "react";
import { Languagepack } from "../languagepack/LanguagepackProvider";

const ErrorServiceHandler = ({ children }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");

  const languagepack = useContext(Languagepack);

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const showDialog = (message) => {
    setDialogMessage(message);
    setDialogOpen(true);
  };

  useEffect(() => {
    errorService.showDialog = showDialog;
  }, []);

  const error =
    languagepack[`error_${dialogMessage}`] || languagepack.error_unknown;

  return (
    <Fragment>
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        sx={{
          zIndex: 10000,
        }}
        PaperProps={{
          sx: { padding: 2 },
        }}
      >
        <Box
          sx={{
            display: "flex",
            minWidth: "25vw",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography>{languagepack.error_occured}</Typography>
          <IconButton onClick={handleDialogClose}>
            <Close />
          </IconButton>
        </Box>
        <Typography>{error}</Typography>
      </Dialog>
      {children}
    </Fragment>
  );
};
class ErrorService {
  constructor() {
    this._showDialog = null;
  }
  set showDialog(showDialog) {
    this._showDialog = showDialog;
  }
  get showDialog() {
    return this._showDialog;
  }
}
export const errorService = new ErrorService();
export default ErrorServiceHandler;
