import AppLayout from "../../components/layouts/AppLayout";
import { RequireLoggedIn } from "../../userContext/UserContext";

const AccountPage = () => {
  return (
    <RequireLoggedIn>
      <AppLayout></AppLayout>
    </RequireLoggedIn>
  );
};
export default AccountPage;
