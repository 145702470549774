import { errorService } from "../services/errorService";
import { userService } from "../services/userService";

const baseURL =
  process.env.NODE_ENV === "production"
    ? "https://teeina-backend.harbys.me/api/v1/"
    : "http://localhost:8080/api/v1/";

class Client {
  baseURL = baseURL;
  headers = {
    "Content-Type": "application/json;charset=utf-8",
  };

  makeHeaders() {
    this.headers.auth = userService.user?.token || "";
    return this.headers;
  }

  async getFeaturedProducts() {
    let response = await fetch(new URL("featured-products", this.baseURL), {
      headers: this.makeHeaders(),
    });
    if (response.ok) {
      return await response.json();
    }
    throw new Error();
  }

  async getProducts(category = "", page = 0, per_page = 20) {
    const params = new URLSearchParams({
      page: page.toString(),
      per_page: per_page.toString(),
    });
    let response = await fetch(new URL(`products?${params}`, this.baseURL), {
      headers: this.makeHeaders(),
      method: "GET",
    });
    if (response.ok) {
      return await response.json();
    }
    throw new Error();
  }

  async login(data) {
    let response = await fetch(new URL("login", this.baseURL), {
      headers: this.makeHeaders(),
      method: "POST",
      body: JSON.stringify(data),
    });
    if (response.ok) {
      let parsed = await response.json();
      if (parsed.code === 0) {
        return parsed;
      }
      throw new Error(parsed.code);
    } else {
      throw new Error();
    }
  }

  async signup(data) {
    let response = await fetch(new URL("signup", this.baseURL), {
      headers: this.makeHeaders(),
      method: "POST",
      body: JSON.stringify(data),
    });
    if (response.ok) {
      let parsed = await response.json();
      if (parsed.code === 0) {
        return parsed;
      }
      throw new Error(parsed.code);
    } else {
      throw new Error();
    }
  }

  async confirmEmail(token) {
    let response = await fetch(
      new URL(`email-verificaion/${token}`, this.baseURL),
      {
        headers: this.makeHeaders(),
      }
    );
    if (response.ok) {
      let parsed = await response.json();
      if (parsed.code === 0) {
        return true;
      }
      throw new Error(parsed.code);
    } else {
      throw new Error();
    }
  }

  async isLoggedIn() {
    let response = await fetch(new URL("user/ping", this.baseURL), {
      headers: this.makeHeaders(),
    });
    if (response.ok) {
      let parsed = await response.json();
      return parsed.code === 0;
    }
    return false;
  }

  handleErrors(error) {
    errorService.showDialog(error.message);
  }
}
const apiClient = new Client();
export default apiClient;
