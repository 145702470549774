import {
  Tabs,
  Tab,
  Box,
  Typography,
  Menu,
  MenuItem,
  IconButton,
  ListItemText,
  SwipeableDrawer,
  List,
  ListItemButton,
  ListItemIcon,
} from "@mui/material";
import { Fragment, useContext } from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  flags,
  Languagepack,
  SelectedLanguage,
} from "../../languagepack/LanguagepackProvider";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import useMobile from "theme/useMobile";
import MenuIcon from "@mui/icons-material/Menu";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import EmojiFoodBeverageOutlinedIcon from "@mui/icons-material/EmojiFoodBeverageOutlined";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import ContactSupportOutlinedIcon from "@mui/icons-material/ContactSupportOutlined";

const remapTabs = (location) => {
  const map = {
    "/login": "/account",
    "/signup": "/account",
    "/signup/welcome": "/account",
  };
  return map[location] ?? location;
};

const Header = () => {
  const languagepack = useContext(Languagepack);
  const { selectedLanguage, setSelectedLanguage } =
    useContext(SelectedLanguage);

  const location = useLocation();
  const navigate = useNavigate();
  const [page, setPage] = useState(remapTabs(location.pathname));
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);
  const languageMenuOpen = Boolean(anchorEl);
  const isMobile = useMobile();

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleChangeLanguage = (lang) => {
    return (event) => {
      setSelectedLanguage(lang);
      handleMenuClose();
    };
  };

  const handleChange = (_, newValue) => {
    setPage(newValue);
    navigate(newValue);
  };

  const tabs = [
    {
      name: languagepack.home,
      href: "/",
      icon: HomeOutlinedIcon,
    },
    {
      name: languagepack.products,
      href: "/products",
      icon: EmojiFoodBeverageOutlinedIcon,
    },
    {
      name: languagepack.cart,
      href: "/cart",
      icon: ShoppingBagOutlinedIcon,
    },
    {
      name: languagepack.account,
      href: "/account",
      icon: AccountCircleOutlinedIcon,
    },
    {
      name: languagepack.contact,
      href: "/contact",
      icon: ContactSupportOutlinedIcon,
    },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: isMobile ? "start" : "center",
      }}
    >
      {!isMobile && (
        <Tabs value={page} onChange={handleChange}>
          {tabs.map((tab) => (
            <Tab key={tab.name} label={tab.name} value={tab.href} />
          ))}
        </Tabs>
      )}
      {isMobile && (
        <Fragment>
          <Box>
            <IconButton
              onClick={() => {
                setMobileDrawerOpen(true);
              }}
              size="large"
            >
              <MenuIcon />
            </IconButton>
          </Box>
          <SwipeableDrawer
            open={mobileDrawerOpen}
            onOpen={() => {
              setMobileDrawerOpen(true);
            }}
            onClose={() => {
              setMobileDrawerOpen(false);
            }}
            PaperProps={{ sx: { minWidth: "70%" } }}
          >
            <List>
              {tabs.map((tab) => (
                <ListItemButton
                  key={tab.name}
                  onClick={() => {
                    handleChange(null, tab.href);
                  }}
                >
                  <ListItemIcon>
                    <tab.icon />
                  </ListItemIcon>
                  <ListItemText primary={tab.name} />
                </ListItemButton>
              ))}
            </List>
          </SwipeableDrawer>
        </Fragment>
      )}
      <Box
        sx={{
          position: "absolute",
          right: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            userSelect: "none",
          }}
          onClick={handleMenuClick}
          fontSize={24}
        >
          {flags[selectedLanguage]}
        </Typography>
        <IconButton onClick={handleMenuClick}>
          <ArrowDropDownIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={languageMenuOpen}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleChangeLanguage("pl")}>{flags.pl}</MenuItem>
          <MenuItem onClick={handleChangeLanguage("en")}>{flags.en}</MenuItem>
        </Menu>
      </Box>
    </Box>
  );
};
export default Header;
