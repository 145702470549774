import AppLayout from "../../components/layouts/AppLayout";
import SignupWelcome from "../../components/sessions/SignupWelcome";
import { Box } from "@mui/material";

const SignupWelcomePage = () => {
  return (
    <AppLayout>
      <Box
        sx={{
          display: "flex",
          minHeight: "60vh",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <SignupWelcome />
      </Box>
    </AppLayout>
  );
};
export default SignupWelcomePage;
