import { Fragment, useState } from "react";
import { Box, Button } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import AdminMenu from "../menu/AdminMenu";

const AdminLayout = ({ children }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = (open) => {
    return () => {
      setMenuOpen(open);
    };
  };

  return (
    <Fragment>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box>
          <Button
            size="large"
            startIcon={<MenuIcon />}
            onClick={() => {
              setMenuOpen(true);
            }}
          >
            Menu
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {children}
        </Box>
      </Box>
      <AdminMenu open={menuOpen} toggleMenu={toggleMenu} />
    </Fragment>
  );
};

export default AdminLayout;
